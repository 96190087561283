.App {
    width: 100%;
}

header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

header .left {
    width: 50%;
}

header .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 50%;
    padding: 0.6em;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row .col {
    display: flex;
    flex-direction: column;
}

a {
    color: var(--blue);
    text-decoration: none;
}

h1 {
    font-family: EvangelionRegular;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 3.5em;
    color: var(--blue);
}
h2 {
    font-family: EvangelionItalic;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 2.5em;
    color: var(--blue);
}

.Main {
    margin: 0 16px;
}

.RunList {
    width: 100%;
}

.RunList .RunRow:nth-child(odd) {
    background-color: var(--black2);
}

.RunRow {
    width: 100%;
    padding: 5px;
}

.RunRow .col {
    width: 20%;
}

.RunRow .run-link {
    text-align: right;
}

.RunRow .np {
    font-size: 1.4em;
}

.RunRow .data {
    font-size: 1.4em;
}

.RunTitle {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 1.25em;
}

.RunTitle .col {
    width: 20%;
}

.imgWrapper {
    position: relative;
}

.imgWrapper .runner-id {
    position: absolute;
    bottom: 2px;
    left: 2px;
    color: var(--yellow);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 0.9em;
}

.runner {
    width: 70px;
    height: 70px;
    opacity: 0.65;
    border-radius: 2px;
}
